import React, {useEffect, useState} from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import API_ROUTE from "../../utils/apiRoute";

const UpdateSchoolModal = ({ isShowing, toggle, setActionFeedbackModalTitleText, setActionFeedbackModalAction, toggleActionFeedbackModal }) => {
    const [allSchools, setAllSchools] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState(null);

    useEffect(() => {
        let didCancel = false;

        const fetchData = async () => {
            try {
                const schoolResponse = await axios.get(`${API_ROUTE}school/all`, {
                    withCredentials: true,
                });
                if (!didCancel) {
                    setAllSchools(schoolResponse.data.data.allSchools);
                }
            } catch (err) {
                if (!didCancel) {
                    console.error(err);
                }
            }
        };

        fetchData();

        return () => {
            didCancel = true;
        };
    }, [setAllSchools]);

    const handleSchoolChange = (event) => {
        const school = allSchools.find(school => school.code === Number(event.target.value));
        setSelectedSchool(school);
    };

    const handleUpdate = () => {
        axios.put(`${API_ROUTE}school/${selectedSchool.code}`, selectedSchool, {
            withCredentials: true,
        })
            .then(() => {
                setActionFeedbackModalTitleText("Escola atualizada com sucesso!");
                setActionFeedbackModalAction(true);
                toggleActionFeedbackModal();
                toggle();
            })
            .catch(error => {
                console.error("Error updating school:", error);
                alert("Erro ao atualizar a Escola");
            });
    };

    const handleReservationChange = (index, key, value) => {
        const updatedReservations = [...selectedSchool.reservationCodesForSumarios];
        updatedReservations[index][key] = value;
        setSelectedSchool({ ...selectedSchool, reservationCodesForSumarios: updatedReservations });
    };

    return (
        <Modal show={isShowing} onHide={toggle}>
            <Modal.Header closeButton>
                <Modal.Title>Atualizar Escola</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Selecione a Escola</Form.Label>
                        <Form.Control as="select" onChange={handleSchoolChange}>
                            <option value="">Selecione...</option>
                            {allSchools.map(school => (
                                <option key={school.code} value={school.code}>{school.name} ({school.code})</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    {selectedSchool && (
                        <>
                            <Form.Group>
                                <Form.Label>Nome da Escola</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={selectedSchool.name}
                                    onChange={(e) => setSelectedSchool({ ...selectedSchool, name: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Código</Form.Label>
                                <Form.Control type="text" value={selectedSchool.code} disabled />
                            </Form.Group>
                            <Form.Group>
                                <Form.Check
                                    type="checkbox"
                                    label="Ativo"
                                    checked={selectedSchool.active}
                                    onChange={(e) => setSelectedSchool({ ...selectedSchool, active: e.target.checked })}
                                />
                            </Form.Group>
                            {selectedSchool.reservationCodesForSumarios?.slice(0, 2).map((reservation, index) => (
                                <div key={index} className="reservation-entry">
                                    <h5>Reservas para Sumários - Semestre {reservation.semester}</h5>
                                    <Form.Group>
                                        <Form.Label>Ano</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={reservation.year}
                                            onChange={(e) => handleReservationChange(index, "year", Number(e.target.value))}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Plano</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={reservation.plan}
                                            onChange={(e) => handleReservationChange(index, "plan", Number(e.target.value))}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Curso</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={reservation.course}
                                            onChange={(e) => handleReservationChange(index, "course", Number(e.target.value))}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Unidade Curricular</Form.Label>
                                        <Form.Control
                                            type="number"
                                            value={reservation.curricularUnit}
                                            onChange={(e) => handleReservationChange(index, "curricularUnit", Number(e.target.value))}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Nome da Unidade Curricular</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={reservation.nameOfCurricularUnit}
                                            onChange={(e) => handleReservationChange(index, "nameOfCurricularUnit", e.target.value)}
                                        />
                                    </Form.Group>
                                </div>
                            ))}
                        </>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={toggle}>Cancelar</Button>
                <Button variant="primary" onClick={handleUpdate} disabled={!selectedSchool}>Atualizar</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UpdateSchoolModal;