import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import ActionFeedbackModal from "../../components/action-feedback-modal/action-feedback-modal.component";
import useModal from "../../utils/useModal";
import API_ROUTE from "../../utils/apiRoute";

const EmployeeEditModal = ({ isShowing, toggle, employees, selectedEmployee, setSelectedEmployee }) => {
    const [updatedEmployee, setUpdatedEmployee] = useState(selectedEmployee || {});
    const [allSchools, setAllSchools] = useState([]);

    const {
        isShowing: isShowingActionFeedbackModal,
        toggle: toggleActionFeedbackModal,
    } = useModal();

    useEffect(() => {
        let didCancel = false;

        const fetchData = async () => {
            try {
                const schoolResponse = await axios.get(`${API_ROUTE}school/all`, {
                    withCredentials: true,
                });
                if (!didCancel) {
                    setAllSchools(schoolResponse.data.data.allSchools);
                }
            } catch (err) {
                if (!didCancel) {
                    console.error(err);
                }
            }
        };

        fetchData();

        return () => {
            didCancel = true;
        };
    }, []);

    const handleEmployeeChange = (selectedOption) => {
        const employee = employees.find(emp => emp.email === selectedOption.value);
        setUpdatedEmployee(employee);
    };

    const handleSchoolChange = (selectedOption) => {
        setUpdatedEmployee({ ...updatedEmployee, schoolCode: selectedOption.value });
    };

    const handleUpdate = () => {
        axios.put(`${API_ROUTE}employee/${updatedEmployee._id}`, updatedEmployee, {
            withCredentials: true,
        })
            .then(() => {
                toggleActionFeedbackModal();
                toggle();
            })
            .catch(error => {
                console.error("Error updating school:", error);
                alert("Erro ao atualizar o utilizador");
                toggle();
            });
    };

    const employeeOptions = employees.map(emp => ({
        value: emp.email,
        label: `${emp.name} (${emp.email.split("@")[0]})`
    }));

    const schoolOptions = allSchools.map(school => ({
        value: school.code,
        label: `${school.name} (Código: ${school.code})`
    }));

    return (
        <>
            <Modal show={isShowing} onHide={toggle}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Funcionário</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Buscar Funcionário</Form.Label>
                            <Select
                                options={employeeOptions}
                                value={employeeOptions.find(opt => opt.value === updatedEmployee?.email) || null}
                                onChange={handleEmployeeChange}
                                placeholder="Selecione ou digite o nome do funcionário"
                            />
                        </Form.Group>
                        {updatedEmployee && (
                            <>
                                <Form.Group className="mt-3">
                                    <Form.Label>Código da Escola</Form.Label>
                                    <Select
                                        options={schoolOptions}
                                        value={schoolOptions.find(opt => opt.value === updatedEmployee?.schoolCode) || null}
                                        onChange={handleSchoolChange}
                                        placeholder="Selecione a escola"
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Check
                                        type="checkbox"
                                        label="É Professor"
                                        checked={updatedEmployee.isProfessor || false}
                                        onChange={(e) => setUpdatedEmployee({ ...updatedEmployee, isProfessor: e.target.checked })}
                                    />
                                </Form.Group>
                            </>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={toggle}>Cancelar</Button>
                    <Button variant="primary" onClick={handleUpdate} disabled={!updatedEmployee}>Salvar</Button>
                </Modal.Footer>
            </Modal>
            <ActionFeedbackModal
                isShowing={isShowingActionFeedbackModal}
                toggle={toggleActionFeedbackModal}
                titleText="Criado os Utilizadores com Sucesso!"
                isActionCompleted={true}
            />
        </>
    );
};

export default EmployeeEditModal;
