import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback, useContext,
} from "react";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import API_ROUTE from "../../utils/apiRoute";
import { useSelector } from "react-redux";
import axios from "axios";
import RequestModal from "../../components/request-modal/request-modal.component";
import useModal from "../../utils/useModal";
import ActionFeedbackModal from "../../components/action-feedback-modal/action-feedback-modal.component";
import "./filter-space.styles.scss";
import { isFirstStringTimeGreaterThanSecond } from "../../utils/time";
import {GlobalContext} from "../../GlobalState";
import WarningFeedbackModal from "../../components/action-feedback-modal/warning-feedback-modal.component";

const FilterSpacePage = ({}) => {
  const userName = useSelector((state) => state.employee.employeeName);
  const userRole = useSelector((state) => state.employee.employeeRole);

  const history = useHistory();
  const [dateInputs, setDateInputs] = useState([
    {
      date: new Date().toISOString().slice(0, 10),
      timeStart: "08:00",
      timeEnd: "12:00",
    },
  ]);
  const [selectedDateForRequest, setSelectedDateForRequest] = useState([]);

  const { isShowing: isShowingReserveSpace, toggle: toggleReserveSpace } =
    useModal();

  const {
    isShowing: isShowingActionFeedbackModal,
    toggle: toggleActionFeedbackModal,
  } = useModal();

  const {
    isShowing: isShowingErrorFeedbackModal,
    toggle: toggleErrorFeedbackModal,
  } = useModal();

  const { isShowing: isShowingWarningModal, toggle: toggleWarningModal } = useModal();

  const reasonRef = useRef(null);
  const reservationDescriptionRef = useRef(null);
  const userRef = useRef(null);

  const [isSearchBtnDisabled, setIsSearchBtnDisabled] = useState(false);

  const [results, setResults] = useState([]);
  const [selectedSpaceId, setSelectedSpaceId] = useState(null);
  const [showReserveSpaceBtn, setShowReserveSpaceBtn] = useState(false);

  const [allSpaceTypes, setAllSpaceTypes] = useState([]);
  const [selectedSpaceType, setSelectedSpaceType] = useState("");
  const [allResources, setAllResources] = useState([]);
  const [selectedResource, setSelectedResource] = useState(null);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedTechnician, setSelectedTechnician] = useState(null);
  const [selectedResponsible, setSelectedResponsible] = useState("");

  const [capacityOperator, setCapacityOperator] = useState("gt");
  const [capacity, setCapacity] = useState("");
  const [errorText, setErrorText] = useState("");

  const [selectedSchoolName, setSelectedSchoolName] = useState("");

  const { selectedSchoolCode, setSelectedSchoolCode } = useContext(GlobalContext);

  useEffect(() => {
    if (selectedSchoolCode === 0) return;

    let isMounted = true;

    const fetchData = async () => {
      try {
        if (selectedSchoolCode) {
          const schoolResponse = await axios.get(`${API_ROUTE}school/byCode/${selectedSchoolCode}`, {
            withCredentials: true,
          });

          if (isMounted) {
            setSelectedSchoolName(schoolResponse.data.data.schoolByCode.name);
          }
        }
      } catch (err) {
        if (isMounted) {
          console.error(err);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false; // Cleanup: set isMounted to false when component unmounts
    };
  }, [selectedSchoolCode]);

  const reserveSpace = useCallback(async () => {
    if (!selectedSpaceId) return;

    await axios
      .post(
        `${API_ROUTE}reservation/${selectedSpaceId}`,
        {
          stringDates: selectedDateForRequest,
          description: reservationDescriptionRef.current.value,
          reason: reasonRef.current.value,
          createForUserId: userRef?.current?.value,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        toggleActionFeedbackModal();
      })
      .catch((err) => {
        setErrorText(err.response.data.message);
        if (err.response.status === 409) {
          toggleWarningModal();
        } else {
          toggleErrorFeedbackModal();
        }
      });
  }, [selectedSpaceId]);

  const handleChangeCapOperator = (e) => {
    setCapacityOperator(e.target.value);
  };

  const handleChangeCapacity = (e) => {
    setCapacity(e.target.value);
  };

  const goToUrl = (url) => {
    history.push(url);
  };

  const createResultsMarkup = useCallback(
    (results, showReserveBtn) => {
      let tmpMarkup = [];
      results.forEach((space) => {
        const resultMarkup = (
          <div className="resultsWrapper">
            <span>{space.name}</span>
            <span>Capacidade {space.capacity}</span>

            <div>
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => {
                  goToUrl(
                    `floor/${space.floorId}/area/${space.areaId}/spaceinfo/${space._id}`
                  );
                }}
              >
                Ver Mais Informações
              </Button>
              {showReserveBtn ? (
                <Button
                  size="sm"
                  variant="outline-primary"
                  onClick={() => {
                    setSelectedSpaceId(space._id);
                    toggleReserveSpace();
                  }}
                >
                  Fazer Reserva
                </Button>
              ) : null}
            </div>
          </div>
        );
        tmpMarkup.push(resultMarkup);
      });

      if (!tmpMarkup.length)
        tmpMarkup.push(<div>Nenhum espaço foi encontrado :(</div>);

      setResults(React.Children.toArray(tmpMarkup));
    },
    [showReserveSpaceBtn]
  );

  // Three next functions are used to convert the selected dates into the expected input by API createReservation
  const convertTimeToFloat = (time) => {
    if (time.split(":")[1].includes("30"))
      return parseFloat(time.split(":")[0]) + 0.5;

    return parseFloat(time.split(":")[0]);
  };

  const convertFloatToTime = (floatTime) => {
    if (String(floatTime).split(".")[1] === "5")
      return `${String(floatTime).split(".")[0]}-30`;

    return `${String(floatTime).split(".")[0]}-0`;
  };

  const convertMonthToZeroIndexBased = (dashedDate) => {
    const year = dashedDate.split("-")[0];
    let month = parseInt(dashedDate.split("-")[1]) - 1;
    const day = dashedDate.split("-")[2];

    return `${year}-${month}-${day}`;
  };

  // Return an array based on time interval like: [2020-08-15-14-0, 2020-08-15-14-30, 2020-08-15-15-0] ...
  const formatSelectedDate = (date) => {
    let formattedArray = [];
    const dateOnly = convertMonthToZeroIndexBased(date.date);
    const timeStart = convertTimeToFloat(date.timeStart);
    const timeEnd = convertTimeToFloat(date.timeEnd);
    for (let i = timeStart; i < timeEnd; i += 0.5) {
      convertFloatToTime(i);
      formattedArray.push(dateOnly + "-" + convertFloatToTime(i));
    }

    return formattedArray;
  };

  const searchSpace = async () => {
    // Format all inputs
    const technicians = selectedTechnician
      ? selectedTechnician.map((tech) => tech.value)
      : "";
    const resources = selectedResource
      ? selectedResource.map((resource) => resource.value)
      : "";

    const typeId = selectedSpaceType ? selectedSpaceType.value : "";

    const responsibleId = selectedResponsible ? selectedResponsible.value : "";

    // query format = ?capacity[gte]=20
    const capacityProperty =
      capacity !== "" ? `capacity[${capacityOperator}]` : "capacity";

    let showReserveBtn = false;

    // if an availability was provided then it will show reserve btn
    if (dateInputs.length) {
      let formattedDate = [];
      dateInputs.forEach((date) => {
        const tmp = formatSelectedDate(date);
        formattedDate = formattedDate.concat(tmp);
      });
      setSelectedDateForRequest(formattedDate);
      setShowReserveSpaceBtn(true);
      showReserveBtn = true;
    } else {
      setSelectedDateForRequest([]);
      setShowReserveSpaceBtn(false);
    }

    if (selectedSchoolCode) {
      await axios
          .get(`${API_ROUTE}space/school/${selectedSchoolCode}`, {
            withCredentials: true,
            params: {
              typeId,
              responsibleId,
              technicians,
              resources,
              [capacityProperty]: capacity,
              availability: dateInputs,
            },
          })
          .then((res) => {
            createResultsMarkup(res.data.data.allSpaces, showReserveBtn);
          })
          .catch((err) => console.log(err));
    }
  };

  const addNewInput = () => {
    setDateInputs((prevState) => {
      let tmpState = [...prevState];
      let defaultValues = {
        date: new Date().toISOString().slice(0, 10),
        timeStart: "08:00",
        timeEnd: "12:00",
      };
      if (tmpState.length > 0) {
        defaultValues = { ...tmpState[tmpState.length - 1] };
        let tomorrow = new Date(defaultValues.date);
        tomorrow.setDate(tomorrow.getDate() + 1);
        defaultValues.date = tomorrow.toISOString().slice(0, 10);
      }
      tmpState.push(defaultValues);
      return tmpState;
    });
  };

  const removeInput = (idxToRemove) => {
    setDateInputs((prevState) => {
      let tmpState = [...prevState];
      tmpState = tmpState.filter((input, idx) => idx !== idxToRemove);
      return tmpState;
    });
  };

  const handleDateInputChange = (idx, inputName, evt) => {
    const val = evt.target.value;
    setDateInputs((prevState) => {
      let tmpState = [...prevState];
      tmpState[idx][inputName] = val;
      return tmpState;
    });
  };

  const dates = useMemo(() => {
    return React.Children.toArray(
      dateInputs.map((input, idx) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "1rem",
            }}
          >
            <div className="dateWrapper">
              <div className="dateWrapperItemWrapper">
                <span>Data:</span>
                <input
                  type="date"
                  value={input.date}
                  onChange={(e) => {
                    handleDateInputChange(idx, "date", e);
                  }}
                />
              </div>
              <div className="dateWrapperItemWrapper">
                <span>Hora Início:</span>
                <input
                  type="time"
                  value={input.timeStart}
                  onChange={(e) => {
                    handleDateInputChange(idx, "timeStart", e);
                  }}
                />
              </div>
              <div className="dateWrapperItemWrapper">
                <span>Hora Fim:</span>
                <input
                  type="time"
                  value={input.timeEnd}
                  onChange={(e) => {
                    handleDateInputChange(idx, "timeEnd", e);
                  }}
                />
              </div>
            </div>
            <Button
              style={{ marginLeft: "5%" }}
              variant="danger"
              size="sm"
              onClick={() => {
                removeInput(idx);
              }}
            >
              Remover
            </Button>
          </div>
        );
      })
    );
  }, [dateInputs]);

  useEffect(() => {
    const isAllDatesValid = dateInputs.some((input) => {
      if (input.timeStart === "" || input.timeEnd === "")
        return true;

      return isFirstStringTimeGreaterThanSecond(input.timeStart, input.timeEnd);
    });
    setIsSearchBtnDisabled(isAllDatesValid);
  }, [dateInputs]);

  const handleSelectInputChange = (field, selected) => {
    switch (field) {
      case "spaceType":
        setSelectedSpaceType(selected);
        break;
      case "resource":
        setSelectedResource(selected);
        break;
      case "technician":
        setSelectedTechnician(selected);
        break;
      case "responsible":
        setSelectedResponsible(selected);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    let didCancel = false;
    (async () => {
      if (selectedSchoolCode) {
        await axios
            .get(`${API_ROUTE}type/school/${selectedSchoolCode}`, {
              withCredentials: true,
            })
            .then((res) => {
              if (didCancel) return;
              const allSpaceTypes = res.data.data.allTypesBySchool;
              const allSpaceTypesOptions = allSpaceTypes.map((type) => {
                return {
                  value: type._id,
                  label: type.name,
                };
              });
              setAllSpaceTypes(allSpaceTypesOptions);
            })
            .catch((err) => console.log(err));

        await axios
            .get(`${API_ROUTE}resource/school/${selectedSchoolCode}`, {
              withCredentials: true,
            })
            .then((res) => {
              if (didCancel) return;
              const allResources = res.data.data.allResourcesBySchool;
              const allTypesOptions = allResources.map((resource) => {
                return {
                  value: resource._id,
                  label: resource.name,
                };
              });
              setAllResources(allTypesOptions);
            })
            .catch((err) => console.log(err));

        await axios
            .get(`${API_ROUTE}employee/getAll/school/${selectedSchoolCode}`, {
              withCredentials: true,
            })
            .then((res) => {
              if (didCancel) return;
              const allUsers = res.data.data.employees;
              const allUsersOptions = allUsers.map((user) => {
                return {
                  value: user._id,
                  label: `${user.name} (${user.email.split("@")[0]})`,
                };
              });
              setAllUsers(allUsersOptions);
            })
            .catch((err) => console.error(err));
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [setSelectedSchoolCode]);

  return (
    <div className="pageWrapper">
      <h3 style={{ width: "100%", marginTop: "1rem" }}>Filtrar Espaços - {selectedSchoolName}</h3>
      <div className="filterSpace__selectWrapper">
        <div style={{ width: "25%", marginTop: "1rem" }}>
          <h5>Tipo de Espaço</h5>
          <Select
            isClearable={true}
            placeholder="Selecione ou digite o tipo de espaço"
            options={allSpaceTypes}
            value={selectedSpaceType}
            onChange={(e) => handleSelectInputChange("spaceType", e)}
            className="filterSpace__selectInput"
          />
        </div>

        <div style={{ width: "25%", marginTop: "1rem" }}>
          <h5>Técnicos</h5>
          <Select
            isMulti
            isClearable={true}
            placeholder="Selecione ou digite o nome do(s) técnico(s)"
            options={allUsers}
            value={selectedTechnician}
            onChange={(e) => handleSelectInputChange("technician", e)}
            className="filterSpace__selectInput"
          />
        </div>

        <div style={{ width: "25%", marginTop: "1rem" }}>
          <h5>Recursos</h5>
          <Select
            isMulti
            isClearable={true}
            placeholder="Selecione ou digite o nome do(s) recurso(s)"
            options={allResources}
            value={selectedResource}
            onChange={(e) => handleSelectInputChange("resource", e)}
            className="filterSpace__selectInput"
          />
        </div>

        <div style={{ width: "25%", marginTop: "1rem" }}>
          <h5>Responsável</h5>
          <Select
            isClearable={true}
            placeholder="Selecione ou digite o nome do responsável"
            options={allUsers}
            value={selectedResponsible}
            onChange={(e) => handleSelectInputChange("responsible", e)}
            className="filterSpace__selectInput"
          />
        </div>

        <div
          style={{
            marginTop: "1rem",
            marginBottom: "10px",
          }}
          className="customSelectWrapper"
        >
          <h5>Capacidade</h5>
          <div className="selectValueWrapper">
            <select
              className="selectBtn filterSpace__selectInput"
              value={capacityOperator}
              onChange={handleChangeCapOperator}
            >
              <option value="gt">Maior que</option>
              <option value="gte">Maior igual que</option>
              <option value="lt">Menor que</option>
              <option value="lte">Menor igual que</option>
              <option value="eq">Exatamente igual que</option>
            </select>
            <input
              type="number"
              className="capacityBtn"
              min="0"
              value={capacity}
              onChange={handleChangeCapacity}
              onKeyDown={(event) => {
                const keyCode = event.keyCode;
                if (
                  keyCode === 69 ||
                  keyCode === 189 ||
                  keyCode === 187 ||
                  keyCode === 188 ||
                  keyCode === 190
                )
                  event.preventDefault();
              }}
            />
          </div>
        </div>
      </div>

      <div style={{ marginTop: "2rem" }}>
        <h3>Disponibilidade Pretendida</h3>
        {dates}
        <Button onClick={addNewInput} variant="dark" className="mt-2">
          +
        </Button>
      </div>

      <Button
        variant="primary"
        onClick={searchSpace}
        className="searchBtn"
        disabled={isSearchBtnDisabled}
      >
        Procurar
      </Button>

      <div style={{ position: "relative", marginTop: "2rem" }}>
        <h3>Resultados</h3>
        {results}
      </div>

      <RequestModal
        isShowing={isShowingReserveSpace}
        toggle={toggleReserveSpace}
        selectRef={reasonRef}
        inputRef={reservationDescriptionRef}
        userRef={userRef}
        userName={userName}
        userRole={userRole}
        requestReservation={reserveSpace}
        spaceId={selectedSpaceId}
      />

      <ActionFeedbackModal
        isShowing={isShowingActionFeedbackModal}
        toggle={toggleActionFeedbackModal}
        titleText="Requisição criada com sucesso!"
        isActionCompleted={true}
      />

      <ActionFeedbackModal
          isShowing={isShowingErrorFeedbackModal}
          toggle={toggleErrorFeedbackModal}
          titleText={errorText}
          isActionCompleted={false}
      />

      <WarningFeedbackModal
          isShowing={isShowingWarningModal}
          toggle={toggleWarningModal}
          titleText={errorText}
      />
    </div>
  );
};

FilterSpacePage.propTypes = {};

export default FilterSpacePage;
