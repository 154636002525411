import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import API_ROUTE from "../../utils/apiRoute";

const CreateSchoolModalComponent = ({ isShowing, toggle, setActionFeedbackModalTitleText, setActionFeedbackModalAction, toggleActionFeedbackModal }) => {
    const [schoolData, setSchoolData] = useState({
        name: "",
        code: "",
        active: true,
        reservationCodesForSumarios: [
            { year: "", semester: 1, plan: "", course: "", curricularUnit: "", nameOfCurricularUnit: "" },
            { year: "", semester: 2, plan: "", course: "", curricularUnit: "", nameOfCurricularUnit: "" }
        ]
    });
    const [availableSchools, setAvailableSchools] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        const fetchSchools = async () => {
            try {
                const response = await axios.get(`${API_ROUTE}school/sumarios`, {
                    withCredentials: true,
                });
                setAvailableSchools(response.data.data.schools);
            } catch (error) {
                console.error("Error fetching available schools:", error);
            }
        };

        fetchSchools();
    }, []);

    useEffect(() => {
        setIsFormValid(schoolData.name !== "" && schoolData.code !== "");
    }, [schoolData]);

    const handleSchoolSelect = (selectedOption) => {
        setSelectedSchool(selectedOption);
        setSchoolData({ ...schoolData, name: selectedOption.value.schoolName, code: selectedOption.value.schoolCode });
    };

    const handleReservationChange = (index, key, value) => {
        const updatedReservations = [...schoolData.reservationCodesForSumarios];
        updatedReservations[index][key] = value;
        setSchoolData({ ...schoolData, reservationCodesForSumarios: updatedReservations });
    };

    const handleSubmit = () => {
        if (!isFormValid) return;

        axios.post(`${API_ROUTE}school/`, schoolData, {
            withCredentials: true,
        })
            .then(() => {
                setActionFeedbackModalTitleText("Escola criada com sucesso!");
                setActionFeedbackModalAction(true);
                toggleActionFeedbackModal();
                toggle();
            })
            .catch(error => {
                console.error("Error creating school:", error);
                alert("Erro ao criar a Escola");
            });
    };

    const schoolOptions = availableSchools.map(school => ({
        value: {
            schoolCode: school.codEscola,
            schoolName: school.abreviatura,
        },
        label: `${school.nome} - ${school.abreviatura} - ${school.codEscola}`
    }));

    return (
        <Modal show={isShowing} onHide={toggle}>
            <Modal.Header closeButton>
                <Modal.Title>Criar Nova Escola</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Selecionar Escola Disponível</Form.Label>
                        <Select
                            options={schoolOptions}
                            value={selectedSchool}
                            onChange={handleSchoolSelect}
                            placeholder="Selecione uma escola"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Nome da Escola</Form.Label>
                        <Form.Control type="text" value={schoolData.name} readOnly />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Código</Form.Label>
                        <Form.Control type="number" value={schoolData.code} readOnly />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check type="checkbox" label="Ativo" checked={schoolData.active} onChange={(e) => setSchoolData({ ...schoolData, active: e.target.checked })} />
                    </Form.Group>
                    <h5>Reservas para Sumários</h5>
                    {schoolData.reservationCodesForSumarios.map((reservation, index) => (
                        <div key={index} className="reservation-entry">
                            <h6>Semestre {reservation.semester}</h6>
                            <Form.Group>
                                <Form.Label>Ano</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={reservation.year}
                                    onChange={(e) => handleReservationChange(index, "year", Number(e.target.value))}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Plano</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={reservation.plan}
                                    onChange={(e) => handleReservationChange(index, "plan", Number(e.target.value))}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Curso</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={reservation.course}
                                    onChange={(e) => handleReservationChange(index, "course", Number(e.target.value))}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Unidade Curricular</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={reservation.curricularUnit}
                                    onChange={(e) => handleReservationChange(index, "curricularUnit", Number(e.target.value))}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Nome da Unidade Curricular</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={reservation.nameOfCurricularUnit}
                                    onChange={(e) => handleReservationChange(index, "nameOfCurricularUnit", e.target.value)}
                                />
                            </Form.Group>
                        </div>
                    ))}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={toggle}>Cancelar</Button>
                <Button variant="primary" onClick={handleSubmit} disabled={!isFormValid}>Criar</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateSchoolModalComponent;
