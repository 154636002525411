import React, {useState, useEffect} from "react";
import { FaEdit, FaBook, FaArrowLeft } from "react-icons/fa";
import { Button } from "react-bootstrap";
import axios from "axios";
import TableCell from "../../components/table-cell/table-cell.component";
import RequestModal from "../../components/request-modal/request-modal.component";
import ConfirmationModal from "../../components/confirmation-modal/confirmation-modal.component";
import RefuseReservationModal from "../../components/refuse-reservation-modal/refuse-reservation-modal.component";
import EditReservationModal from "../../components/edit-reservation-modal/edit-reservation-modal.component";
import "./timetable.styles.scss";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import API_ROUTE from "../../utils/apiRoute";
import useModal from "../../utils/useModal";
import ActionFeedbackModal from "../../components/action-feedback-modal/action-feedback-modal.component";
import WarningFeedbackModal from "../../components/action-feedback-modal/warning-feedback-modal.component";

const TimeTable = ({ match, location }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const selectedDate = useSelector((state) => state.space.selectedDate);

  const inputRef = useState(React.createRef())[0];
  const cancelReservationInputRef = useState(React.createRef())[0];
  const selectRef = useState(React.createRef())[0];
  const userRef = useState(React.createRef())[0];

  const [requestMode, setRequestMode] = useState(false);
  const [reservedTimes, setReservedTimes] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userData, setUserData] = useState({});
  const [spaceData, setSpaceData] = useState({});
  const [spaceId, setSpaceId] = useState(null);
  const [arrayOfDatesSelecteds, setArrayOfDatesSelecteds] = useState([]); // array of selecteds that contains dates
  const [selectedReservation, setSelectedReservation] = useState({});

  const [reservationStatus, setReservationStatus] = useState("");

  const colQuantity = selectedDate.length + 1; // plus one, because of the first column with the hours

  const amountOfDivs = colQuantity * 33;
  const counter = { count: 0 }; // counter to iterate through the days selected

  const [tableOfContents, setTableOfContents] = useState(
    new Array(amountOfDivs).fill(null)
  );

  const [arrayOfSelecteds, setArrayOfSelecteds] = useState(
    new Array(amountOfDivs).fill(false)
  );

  const [userName, setUserName] = useState("");
  const userRole = useSelector((state) => state.employee.employeeRole);

  const [errorText, setErrorText] = useState("");

  const {
    isShowing: isShowingRequestReservationModal,
    toggle: toggleRequestReservationModal,
  } = useModal();
  const {
    isShowing: isShowingConfirmationModal,
    toggle: toggleConfirmationModal,
  } = useModal();
  const {
    isShowing: isShowingRefuseReservationModal,
    toggle: toggleRefuseReservationModal,
  } = useModal();
  const {
    isShowing: isShowingEditReservationModal,
    toggle: toggleEditReservationModal,
  } = useModal();
  const {
    isShowing: isShowingErrorFeedbackModal,
    toggle: toggleErrorFeedbackModal,
  } = useModal();
  const { isShowing: isShowingWarningModal, toggle: toggleWarningModal } = useModal();

  const goToPreviousPage = () => {
    history.goBack();
  };

  const handleUpdateReservation = async () => {
    if (reservationStatus !== "") {
      selectedReservation.status = reservationStatus;
      selectedReservation.decisionDate = new Date();
      if (reservationStatus === "rejeitado") {
        selectedReservation.analysisJustification =
          cancelReservationInputRef.current.value;
      }

      if (reservationStatus === "cancelado" && !isAdmin) {
        await axios
          .patch(`${API_ROUTE}reservation/${selectedReservation._id}`, null, {
            withCredentials: true,
          })
          .then((res) => {
            window.location.reload();
          });
        return;
      }

      await axios
        .put(
          `${API_ROUTE}reservation`,
          {
            clickedReservData: selectedReservation,
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          window.location.reload();
        });
    }
  };

  const getData = async () => {
    let sortedAndFilteredReservationData;
    await axios
      .get(
        `${API_ROUTE}reservation/${match.params.timetableId}${location.search}`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        const reservationData = res.data.data.reservationData;
        reservationData.sort(
          (a, b) =>
            new Date(new Date(a.timeStart)) - new Date(new Date(b.timeStart))
        );
        sortedAndFilteredReservationData = reservationData.filter((res) =>
            res.status === "reservado" || res.status === "em análise"
        );
        setReservedTimes(sortedAndFilteredReservationData);
      });

    await axios
      .get(`${API_ROUTE}space/${match.params.timetableId}`, {
        withCredentials: true,
      })
      .then((res) => {
        setSpaceData(res.data.data.spaceData);
      });

    await axios
      .get(`${API_ROUTE}employee`, {
        withCredentials: true,
      })
      .then((res) => {
        setUserName(res.data.user.name);
        setUserData(res.data.user);
        if (res.data.user.role !== "user") {
          setIsAdmin(true);
        }
      });

    return sortedAndFilteredReservationData;
  };

  useEffect(() => {
    formatDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservedTimes]);

  // build an array of formatted hours (to disable cells for 'reservado' requests)
  const buildArrayOfFormattedHourMinute = (reservationData) => {
    let formattedHourMinuteArray = [];
    const timeStart = new Date(reservationData[0].timeStart);
    const timeEnd = new Date(reservationData[0].timeEnd);

    // if the end is 00:00 convert it to 24:00
    let militaryHourEnd =
      timeEnd.getUTCHours() === 0 ? 24 : timeEnd.getUTCHours();

    const hourMinuteStart = formatHourAndMinute(
      timeStart.getUTCHours(),
      timeStart.getUTCMinutes()
    );
    const hourMinuteEnd = formatHourAndMinute(
      militaryHourEnd,
      timeEnd.getUTCMinutes()
    );

    for (let time = hourMinuteStart; time < hourMinuteEnd; time += 0.5) {
      formattedHourMinuteArray.push(time);
    }

    return formattedHourMinuteArray;
  };

  const disableCellOnClick = (selectedDay, reservationData) => {
    const formattedHourMinuteArray =
      buildArrayOfFormattedHourMinute(reservationData);

    setTableOfContents((prevState) => {
      const tmpArr = [...prevState];

      for (let day = 0; day < colQuantity; day++) {
        let j = 0;
        for (let hour = 7.5; hour < 24; hour += 0.5) {
          if (day === selectedDay && formattedHourMinuteArray.includes(hour)) {
            const oldComponentProps = tmpArr[j * colQuantity + day].props;
            tmpArr[j * colQuantity + day] = React.cloneElement(
              tableOfContents[j * colQuantity + day],
              {
                ...oldComponentProps,
                onClickDisabled: true,
              }
            );
          }
          j++;
        }
      }

      return [...tmpArr];
    });
  };

  const setReservedCells = (
    selectedHour,
    selectedDay,
    reservationData,
    boxHeight,
    boxWidth,
    boxMarginLeft,
    isSameHourStart,
    isAcceptBlock
  ) => {
    if (reservationData[0].status === "reservado")
      disableCellOnClick(selectedDay, reservationData);
    setTableOfContents((prevstate) => {
      const tmpArr = [...prevstate];

      for (let day = 0; day < colQuantity; day++) {
        // treat hour = 8 as the first hour of the day
        // hour = 7.5 (first row with the day's name)
        let j = 0;
        for (let hour = 7.5; hour < 24; hour += 0.5) {
          if (day === selectedDay && selectedHour === hour) {
            if (isSameHourStart) {
              const oldComponentProps = tmpArr[j * colQuantity + day].props;
              if (!oldComponentProps.reservationData) continue;

              tmpArr[j * colQuantity + day] = React.cloneElement(
                tableOfContents[j * colQuantity + day],
                {
                  reservationData: [
                    ...oldComponentProps.reservationData,
                    reservationData[0],
                  ],
                  boxHeight: [...oldComponentProps.boxHeight, boxHeight[0]],
                  boxWidth: [...oldComponentProps.boxWidth, boxWidth[0]],
                  boxMarginLeft: [
                    ...oldComponentProps.boxMarginLeft,
                    boxMarginLeft[0],
                  ],
                  isAcceptBlock,
                }
              );
            } else {
              tmpArr[j * colQuantity + day] = React.cloneElement(
                tableOfContents[j * colQuantity + day],
                {
                  reservationData,
                  boxHeight,
                  boxWidth,
                  boxMarginLeft,
                  isAcceptBlock,
                }
              );
            }
          }

          j++;
        }
      }
      return [...tmpArr];
    });
  };

  const formatHourAndMinute = (hour, minute) => {
    if (String(minute).includes("3")) {
      return parseFloat(`${hour}.5`);
    } else {
      return parseFloat(`${hour}`);
    }
  };

  // this function calculates the request box css properties and formats the date data from db
  const formatDate = () => {
    if (reservedTimes) {
      // save the reservation and an array that contains the indexes of the reservation
      let reservationAndConflictsReferences = [];
      // holds the index and the defined boxwidth
      let boxWidthReference = [];
      let marginLeftReference = [];

      reservedTimes.forEach((el, idx) => {
        // verify if there are conflicts of reservations at same time
        let conflictsIndexes = []; // save the reservation's index if there is conflict
        const start1 = new Date(el.timeStart);
        const end1 = new Date(el.timeEnd);
        start1.setUTCSeconds(0);
        start1.setUTCMilliseconds(0);
        end1.setUTCSeconds(0);
        end1.setUTCMilliseconds(0);

        // for each reservation verifies if there are conflicts
        reservedTimes.forEach((element, i) => {
          // if (i !== idx) {
          const start2 = new Date(element.timeStart);
          const end2 = new Date(element.timeEnd);
          start2.setUTCSeconds(0);
          start2.setUTCMilliseconds(0);
          end2.setUTCSeconds(0);
          end2.setUTCMilliseconds(0);

          if (
            (start1.getTime() >= start2.getTime() &&
              start1.getTime() < end2.getTime()) ||
            (start2.getTime() >= start1.getTime() &&
              start2.getTime() < end1.getTime())
          ) {
            conflictsIndexes.push(i);
          }
          // }
        });

        //  array that contains each reservation and all conflicts and their indexes
        reservationAndConflictsReferences.push([el, conflictsIndexes]);
      });

      // element is an array like this [reservation, [1 , 2 , 3]], where the second position holds an
      // array of indexes of conflicts
      reservationAndConflictsReferences.forEach(
        (reservationAndConflicts, idx) => {
          let isSameHourStart = false; // to check if the conflict's reservations starts at the same time

          const startDateTime = new Date(reservationAndConflicts[0].timeStart);
          startDateTime.setUTCSeconds(0);
          startDateTime.setUTCMilliseconds(0);
          const endDateTime = new Date(reservationAndConflicts[0].timeEnd);

          const start1 = startDateTime.getTime();

          // check if reservation starts at the same time
          reservedTimes.forEach((val, i) => {
            // do not check the same reservation and reservations that wasn't verified yet
            if (i !== idx && i < idx) {
              const start2 = new Date(val.timeStart);
              start2.setUTCSeconds(0);
              start2.setMilliseconds(0);

              if (start1 === start2.getTime()) {
                isSameHourStart = true;
              }
            }
          });

          // format the date
          const day = startDateTime.getDate();
          const month = startDateTime.getMonth() + 1;
          const year = startDateTime.getFullYear();
          const startHour = startDateTime.getUTCHours();
          const startMinute = startDateTime.getUTCMinutes();
          const endHour = endDateTime.getUTCHours();
          const endMinute = endDateTime.getUTCMinutes();

          const startHourFormated = formatHourAndMinute(startHour, startMinute);
          let endHourFormated = formatHourAndMinute(endHour, endMinute);

          const yearMonthDay = `${year}-${month}-${day}`;

          const formattedSelectedDate = selectedDate.map((date) =>
            getThreeFirstElementFromDashedString(date)
          );
          // +1 because the day variable inside the for loop isn't 0 index based
          const selectedColId = formattedSelectedDate.indexOf(yearMonthDay) + 1;

          // calculates boxes's css properties
          // default values declaration

          // if the end hour is zero (which means midnight it must be converted to 24)
          if (endHourFormated === 0) {
            endHourFormated = 24;
          }

          // const boxHeight = (endHourFormated - startHourFormated) / 0.5;
          const boxHeight = ((endHourFormated - startHourFormated) / 0.5) * 30;
          let boxMarginLeft = 10;
          let boxWidth = 80;

          // stores the indexes of the reservations that has already been visited
          let visitedReservationIndexArray = [];

          let isAcceptBlock = false;

          // if there is conflict
          if (reservationAndConflicts[1].length > 0) {
            // check if there is one conflict that has already been accept

            reservationAndConflicts[1].forEach((el) => {
              if (reservedTimes[el].status === "reservado") {
                isAcceptBlock = true;
              }
            });

            // formula to calculate boxWidth based on the amount of conflicts
            boxWidth =
              boxWidth / reservationAndConflicts[1].length -
              (reservationAndConflicts[1].length - 1) * 5;
            visitedReservationIndexArray = reservationAndConflicts[1].filter(
              (visitedIdx) => idx > visitedIdx
            );
          }
          // if there are too much conflicts reservations...
          if (boxWidth <= 0) {
            boxWidth = 13;
          }

          // each reservation has his boxWidth stored in this array
          boxWidthReference.push(boxWidth);

          // if there are reservations that was already visited
          if (visitedReservationIndexArray.length > 0) {
            // check if it is possible to use the minimum marginLeft
            if (
              !visitedReservationIndexArray
                .map((id) => {
                  if (marginLeftReference[id] - boxWidthReference[id] === 10)
                    return true;
                })
                .includes(true)
            ) {
              // put the minimum marginLeft possible
              marginLeftReference.push(boxMarginLeft + boxWidth);
            } else {
              // if can't use the minimum marginLeft possible then
              // try to get the lowest margin possible without colliding
              // const visitedMarginArray = visitedReservationIndexArray.map(
              //   id => marginLeftReference[id]
              // );

              // get the lowest margin from the reservations that has already been visited
              // let lowestMargin = Math.min(...visitedMarginArray);

              // visitedMarginArray.forEach(el => {
              //   if (lowestMargin + 10 < el) {
              //     lowestMargin = el;
              //   }
              // });

              // boxMarginLeft = lowestMargin + 10;
              // boxMarginLeft = marginLeftReference[idx - 1] + 10;
              boxMarginLeft =
                reservationAndConflicts[1].indexOf(idx) *
                  boxWidthReference[visitedReservationIndexArray[0]] +
                reservationAndConflicts[1].indexOf(idx) * 10 +
                10;
              marginLeftReference.push(boxMarginLeft);
            }
          } else {
            // put the minimum marginLeft possible
            marginLeftReference.push(boxMarginLeft + boxWidth);
          }

          setReservedCells(
            startHourFormated,
            selectedColId,
            [reservationAndConflicts[0]],
            [boxHeight],
            [boxWidth],
            [boxMarginLeft],
            isSameHourStart,
            isAcceptBlock
          );
        }
      );
    }
  };

  const isDateDashedStringEqual = (date1, date2) => {
    const date1Array = date1.split("-");
    const date2Array = date2.slice(0, 10).split("-");
    let isEqual = true;
    date1Array.forEach((el, idx) => {
      if (parseInt(el) !== parseInt(date2Array[idx])) isEqual = false;
    });

    return isEqual;
  };

  const getColumnIndex = (arrayOfDashedSelectedDates, date2) => {
    const date2Array = date2.slice(0, 10).split("-");
    let index = -2;
    arrayOfDashedSelectedDates.forEach((el, idx) => {
      const dateArray = el.split("-");
      if (
        parseInt(dateArray[0]) === parseInt(date2Array[0]) &&
        parseInt(dateArray[1]) === parseInt(date2Array[1]) &&
        parseInt(dateArray[2]) === parseInt(date2Array[2])
      )
        index = idx;
    });

    return index + 1; // index 0 = column of hours
  };

  useEffect(() => {
    if (!tableOfContents.includes(null)) {
      const updatedArray = tableOfContents.map((val) => {
        return React.cloneElement(val, { userData });
      });
      setTableOfContents(updatedArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    let allReservations;
    (async () => {
      allReservations = await getData();
    })();
    const newSelectedDateArray = selectedDate.map((val) =>
      getThreeFirstElementFromDashedString(val)
    );

    const selectCol = (e) => {
      const selectedDay = e.target.id;

      let reservedHoursForTheSelectedDay = [];
      allReservations.forEach((reservation) => {
        if (
          reservation.status === "reservado" &&
          isDateDashedStringEqual(selectedDay, reservation.timeStart)
        ) {
          reservedHoursForTheSelectedDay =
            reservedHoursForTheSelectedDay.concat(
              buildArrayOfFormattedHourMinute([reservation])
            );
        }
      });

      // +1 because the day variable inside the for loop isn't 0 index based
      const selectedColId = newSelectedDateArray.indexOf(selectedDay) + 1;
      setArrayOfSelectedsBasedOnHourAndDay(
        true,
        selectedColId,
        reservedHoursForTheSelectedDay
      );
    };

    const selectRow = (e) => {
      const selectedHour = parseFloat(e.target.id);
      let allReservedDayAndHours = {};
      allReservations.forEach((reservation) => {
        if (reservation.status === "reservado") {
          const columnIndex = getColumnIndex(
            newSelectedDateArray,
            reservation.timeStart
          );
          allReservedDayAndHours = {
            ...allReservedDayAndHours,
            [columnIndex]: allReservedDayAndHours[columnIndex]
              ? allReservedDayAndHours[columnIndex].concat(
                  buildArrayOfFormattedHourMinute([reservation])
                )
              : buildArrayOfFormattedHourMinute([reservation]),
          };
        }
      });
      setArrayOfSelectedsBasedOnHourAndDay(
        selectedHour,
        true,
        allReservedDayAndHours
      );
    };

    const tmpInitialArr = [];

    for (let day = 0; day < colQuantity; day++) {
      // treat hour = 8 as the first hour of the day
      // hour = 7.5 (first row with the day's name)
      let j = 0;
      for (let hour = 7.5; hour < 24; hour += 0.5) {
        tmpInitialArr[j * colQuantity + day] = (
          <TableCell
            isSelectedProps={
              arrayOfSelecteds ? arrayOfSelecteds[j * colQuantity + day] : false
            }
            day={day}
            hour={hour}
            counter={hour === 7.5 && day !== 0 ? counter.count++ : counter}
            selectedDate={selectedDate}
            requestMode={requestMode}
            selectRow={selectRow}
            selectCol={selectCol}
            selectCell={selectCell}
            userData={userData}
            toggleConfirmationModal={toggleConfirmationModal}
            toggleDeclineReservationModal={toggleRefuseReservationModal}
            toggleEditModal={toggleEditReservationModal}
            setSelectedReservation={setSelectedReservation}
            setReservationStatus={setReservationStatus}
            isAcceptBlock={false}
          />
        );

        j++;
      }
    }
    setTableOfContents(React.Children.toArray(tmpInitialArr));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!tableOfContents.includes(null) && arrayOfSelecteds) {
      const updatedArray = tableOfContents.map((val, idx) => {
        return React.cloneElement(val, {
          isSelectedProps: arrayOfSelecteds[idx],
        });
      });
      setTableOfContents(updatedArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrayOfSelecteds]);

  // if requestMode is changed then update the props of all cells
  useEffect(() => {
    if (!tableOfContents.includes(null) && arrayOfSelecteds) {
      const tmpArr = [...tableOfContents];
      const changedPropsArray = tmpArr.map((val, idx) => {
        return React.cloneElement(tableOfContents[idx], {
          requestMode: requestMode,
          isSelectedProps: false,
        });
      });
      setTableOfContents(changedPropsArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestMode]);

  const getThreeFirstElementFromDashedString = (dashedString) => {
    const tmpArr = dashedString.split("-");
    return `${tmpArr[0]}-${tmpArr[1]}-${tmpArr[2]}`;
  };

  // insert in arrayOfDatesSelected the selected dates
  const setAndRemoveDatesSelected = (
    selectedColId,
    selectedHour,
    remove = false
  ) => {
    let minutes = selectedHour % 1 !== 0 ? 30 : 0;
    const year = parseInt(selectedDate[selectedColId - 1].split("-")[0]);
    const month = parseInt(selectedDate[selectedColId - 1].split("-")[1]) - 1;
    const day = parseInt(selectedDate[selectedColId - 1].split("-")[2]);

    setArrayOfDatesSelecteds((prevState) => {
      let tmpArr = [...prevState];
      const dateObject = new Date(
        year,
        month,
        day,
        parseInt(selectedHour),
        minutes
      );
      if (remove) {
        // tmpArr.splice(tmpArr.indexOf(dateObject));

        return tmpArr.filter((date) => {
          return dateObject.getTime() !== date.getTime();
        });
      }

      tmpArr.push(dateObject);

      return [...tmpArr];
    });
  };

  const setArrayOfSelectedsBasedOnHourAndDay = (
    selectedHour = true,
    selectedColId = true,
    alreadyReservedHours
  ) => {
    setArrayOfSelecteds((prevState) => {
      let tmpArr = [...prevState];
      for (let day = 0; day < colQuantity; day++) {
        let j = 0;
        for (let hour = 7.5; hour < 24; hour += 0.5) {
          // only one cell selected
          if (selectedHour !== true && selectedColId !== true) {
            if (day === selectedColId && hour === selectedHour && day !== 0) {
              if (tmpArr[j * colQuantity + day]) {
                tmpArr[j * colQuantity + day] = false;
                setAndRemoveDatesSelected(selectedColId, selectedHour, true);
              } else {
                tmpArr[j * colQuantity + day] = true;
                setAndRemoveDatesSelected(selectedColId, selectedHour, false);
              }
            }
            // column selected
          } else if (selectedColId !== true) {
            if (day === selectedColId && day !== 0) {
              if (tmpArr[j * colQuantity + day] && hour !== 7.5) {
                tmpArr[j * colQuantity + day] = false;
                setAndRemoveDatesSelected(selectedColId, hour, true);
              } else if (!alreadyReservedHours.includes(hour) && hour !== 7.5) {
                tmpArr[j * colQuantity + day] = true;
                setAndRemoveDatesSelected(selectedColId, hour, false);
              }
            }
            // row selected
          } else {
            if (hour === selectedHour && day !== 0) {
              if (tmpArr[j * colQuantity + day]) {
                tmpArr[j * colQuantity + day] = false;
                setAndRemoveDatesSelected(day, selectedHour, true);
              } else if (
                !alreadyReservedHours[day] ||
                (alreadyReservedHours[day] &&
                  !alreadyReservedHours[day].includes(selectedHour))
              ) {
                tmpArr[j * colQuantity + day] = true;
                setAndRemoveDatesSelected(day, selectedHour, false);
              }
            }
          }
          j++;
        }
      }
      return [...tmpArr];
    });
  };

  const selectCell = (yearId, monthId, dayId, hour) => {
    const newSelectedDateArray = selectedDate.map((val) => {
      const tmpArr = val.split("-");
      return `${tmpArr[0]}-${tmpArr[1]}-${tmpArr[2]}`;
    });
    // +1 because the day variable inside the for loop isn't 0 index based
    const selectedColId =
      newSelectedDateArray.indexOf(`${yearId}-${monthId}-${dayId}`) + 1;
    setArrayOfSelectedsBasedOnHourAndDay(hour, selectedColId);
  };

  const requestReservation = async () => {
    const stringDates = arrayOfDatesSelecteds.map((dat) => {
      return `${dat.getFullYear()}-${dat.getMonth()}-${dat.getDate()}-${dat.getHours()}-${dat.getMinutes()}`;
    });

    await axios
      .post(
        `${API_ROUTE}reservation/${match.params.timetableId}`,
        {
          stringDates,
          description: inputRef.current.value,
          reason: selectRef.current.value,
          createForUserId: userRef?.current?.value,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        setErrorText(err.response.data.message);
        if (err.response.status === 409) {
          toggleWarningModal();
        } else {
          toggleErrorFeedbackModal();
        }
      });
  };

  const toggleMode = () => {
    setRequestMode((prevState) => !prevState);
  };

  const dayQuantity = selectedDate.length;
  const timetableStyle = {
    gridTemplateColumns: `minmax(max-content, 8vw) repeat(${dayQuantity}, minmax(200px, ${
      80 / (dayQuantity === 1 ? 1 : dayQuantity)
    }vw))`,
  };

  return (
    <div
      style={{
        overflowX: "auto",
        overflowY: "auto",
        minHeight: "100vh",
        paddingTop: "1rem",
      }}
    >
      <div className="top-info">
        <div className="space-name">
          <FaArrowLeft
            size={20}
            className="timetable__backIcon"
            onClick={goToPreviousPage}
          />
          <h3>{spaceData.name}</h3>
        </div>

        <div className="timetable__header-graphLegend">
          Legenda:
          <div className="timetable__header-graphLegend timetable__header-graphLegend-wrapper">
            <div className="timetable__legendBox timetable__legendBox-gray" />
            <p>Reserva solicitada</p>
          </div>
          <div className="timetable__header-graphLegend timetable__header-graphLegend-wrapper">
            <div className="timetable__legendBox timetable__legendBox-green" />
            <p>Reserva aprovada</p>
          </div>
        </div>

        {requestMode ? (
          <p>Você está em modo solicitação</p>
        ) : (
          <p>Para solicitar a reserva de um espaço entre em Modo Solicitação</p>
        )}

        <div style={{ minWidth: "350px", marginLeft: "1rem" }}>
          <Button variant="secondary" onClick={toggleMode}>
            {`${requestMode ? "Modo Consulta" : "Modo Solicitação"}`}
            <FaEdit className="icon-timetable icon-right icon-white" />
          </Button>
          {requestMode ? (
            <Button
              className="left-space"
              onClick={() => {
                setSpaceId(spaceData._id)
                toggleRequestReservationModal()
              }}
              disabled={arrayOfDatesSelecteds.length <= 0}
            >
              {isAdmin ? "Realizar Reserva" : "Solicitar Reserva"}
              <FaBook className="icon-timetable icon-right icon-white"></FaBook>
            </Button>
          ) : null}
        </div>
      </div>

      <div className="timetable" style={timetableStyle}>
        {tableOfContents}
      </div>

      <RequestModal
        isShowing={isShowingRequestReservationModal}
        toggle={toggleRequestReservationModal}
        userName={userName}
        userRole={userRole}
        requestReservation={requestReservation}
        inputRef={inputRef}
        selectRef={selectRef}
        userRef={userRef}
        spaceId={spaceId}
      />

      <ConfirmationModal
        isShowing={isShowingConfirmationModal}
        toggle={toggleConfirmationModal}
        handleConfirmation={handleUpdateReservation}
        titleText={
          reservationStatus === "reservado"
            ? "Aceitar Pedido de Reserva"
            : reservationStatus === "rejeitado" &&
              selectedReservation.status === "reservado"
            ? "Cancelar Reserva"
            : reservationStatus === "cancelado" &&
              selectedReservation.status === "reservado"
            ? "Cancelar Reserva"
            : reservationStatus === "cancelado" &&
              selectedReservation.status === "em análise"
            ? "Cancelar Pedido de Reserva"
            : ""
        }
      />

      <RefuseReservationModal
        isShowing={isShowingRefuseReservationModal}
        toggle={toggleRefuseReservationModal}
        declineRequest={handleUpdateReservation}
        inputRef={cancelReservationInputRef}
      />

      <EditReservationModal
        isShowing={isShowingEditReservationModal}
        toggle={toggleEditReservationModal}
        reservationData={selectedReservation}
      />

      <ActionFeedbackModal
          isShowing={isShowingErrorFeedbackModal}
          toggle={toggleErrorFeedbackModal}
          titleText={errorText}
          isActionCompleted={false}
      />

      <WarningFeedbackModal
          isShowing={isShowingWarningModal}
          toggle={toggleWarningModal}
          titleText={errorText}
      />
    </div>
  );
};

export default TimeTable;
