import React, { useState } from "react";
import { Button } from "react-bootstrap";
import useModal from "../../utils/useModal";
import UpdateSchoolModalComponent from "../../components/manage-school-model/update-school-modal.component";
import CreateSchoolModalComponent from "../../components/manage-school-model/create-school-modal.component";
import ActionFeedbackModal from "../../components/action-feedback-modal/action-feedback-modal.component";

const ManageSchools = () => {
    const {
        isShowing: isShowingUpdateSchoolModal,
        toggle: toggleUpdateSchoolModal,
    } = useModal();

    const {
        isShowing: isShowingCreateSchoolModal,
        toggle: toggleCreateSchoolModal,
    } = useModal();

    const {
        isShowing: isShowingActionFeedbackModal,
        toggle: toggleActionFeedbackModal,
    } = useModal();

    const [actionFeedbackModalTitleText, setActionFeedbackModalTitleText] = useState("");
    const [isActionCompleted, setIsActionCompleted] = useState(false);

    return (
        <div className="manageSystemWrapper">
            <div className="buttonPanel">
                <h5>Gerir Escolas</h5>
                <Button variant="outline-info" block onClick={toggleUpdateSchoolModal}>
                    Atualizar Escola
                </Button>
                <Button variant="outline-info" block onClick={toggleCreateSchoolModal}>
                    Criar Nova Escola
                </Button>
            </div>

            <UpdateSchoolModalComponent
                isShowing={isShowingUpdateSchoolModal}
                toggle={toggleUpdateSchoolModal}
                setActionFeedbackModalTitleText={setActionFeedbackModalTitleText}
                setActionFeedbackModalAction={setIsActionCompleted}
                toggleActionFeedbackModal={toggleActionFeedbackModal}
            />

            <CreateSchoolModalComponent
                isShowing={isShowingCreateSchoolModal}
                toggle={toggleCreateSchoolModal}
                setActionFeedbackModalTitleText={setActionFeedbackModalTitleText}
                setActionFeedbackModalAction={setIsActionCompleted}
                toggleActionFeedbackModal={toggleActionFeedbackModal}
            />

            <ActionFeedbackModal
                isShowing={isShowingActionFeedbackModal}
                toggle={toggleActionFeedbackModal}
                isActionCompleted={isActionCompleted}
                titleText={actionFeedbackModalTitleText}
            />
        </div>
    );
};

export default ManageSchools;
