import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Navbar,
  Form,
  Nav,
  InputGroup,
  FormControl,
  NavDropdown,
  Dropdown,
} from "react-bootstrap";
import { FiUser } from "react-icons/fi";
import Select from "react-select";
import {FaHome, FaInfoCircle, FaRegFileExcel, FaBuilding} from "react-icons/fa";
import { MdNotificationsNone } from "react-icons/md";
import { FiLogOut } from "react-icons/fi";
import "./navbar.styles.scss";
import axios from "axios";
import { useHistory } from "react-router-dom";
import API_ROUTE from "../../utils/apiRoute";
import { GlobalContext } from "../../GlobalState";
import { useLocation } from 'react-router-dom';

const NavbarCustom = ({
  isAuthenticated,
  systemMasterManager,
  systemManager,
  spaceManager,
  employeeName,
  logout,
}) => {

  const [spaceName, setSpaceName] = useState("");
  const [allSpaces, setAllSpaces] = useState([]);
  const [spaceOptions, setSpaceOptions] = useState([]);
  const [allSchools, setAllSchools] = useState([]);

  const { selectedSchoolCode, setSelectedSchoolCode } = useContext(GlobalContext);
  const { userSchoolCode } = useContext(GlobalContext);

  const [shouldOpenSearchMenu, setShouldOpenSearchMenu] = useState(false);
  const [isBlockOtherSchools, setIsBlockOtherSchools] = useState(false);

  const searchBarRef = useRef(null);

  const history = useHistory();

  const location = useLocation();

    useEffect(() => {
        const path = location.pathname;
        const pathsThatAreNecessaryBlockOthersSchools = [
            '/manage-system', '/myspaces', '/delete-reservation', '/permissions', '/reservationReasons', '/notifications'
        ]
        if (!systemMasterManager) {
            if (pathsThatAreNecessaryBlockOthersSchools.includes(path)) {
                setIsBlockOtherSchools(true)
                setSelectedSchoolCode(userSchoolCode)
            }
        }
    }, [location.pathname]);

    useEffect(() => {
        let didCancel = false;

        const fetchData = async () => {
            try {
                const schoolResponse = await axios.get(`${API_ROUTE}school`, {
                    withCredentials: true,
                });
                if (!didCancel) {
                    setAllSchools(schoolResponse.data.data.allSchools);
                }

                if (selectedSchoolCode) {
                    const spaceResponse = await axios.get(`${API_ROUTE}space/school/${selectedSchoolCode}`, {
                        withCredentials: true,
                    });
                    if (!didCancel) {
                        setAllSpaces(spaceResponse.data.data.allSpaces);
                        setSpaceOptions(spaceResponse.data.data.allSpaces.map(space => ({
                            label: space.name,
                            value: space._id
                        })));
                    }
                }
            } catch (err) {
                if (!didCancel) {
                    console.error(err);
                }
            }
        };

        fetchData();

        return () => {
            didCancel = true;
        };
    }, [setAllSpaces, setAllSchools, setSelectedSchoolCode]);

    useEffect(() => {
        if (selectedSchoolCode !== 0) {
            localStorage.setItem('savedSchoolCode', selectedSchoolCode.toString());
        }
    }, [selectedSchoolCode]);

    const handleSchoolSelectChange = (event) => {
        setSelectedSchoolCode(event.target.value);
        goToUrl('/home');
    };

    const handleSpaceSelectChange = (event) => {
        const space = allSpaces.find((space) => space._id === event.value);
        if (space) {
            goToUrl(
                `/floor/${space.floorId}/area/${space.areaId}/spaceinfo/${space._id}`
            );
        }
    };

  const goToUrl = (url) => {
    history.push(url);
  };

  const dropdownSearch = () => {
    if (
      shouldOpenSearchMenu &&
      searchBarRef.current &&
      searchBarRef.current.offsetWidth &&
      spaceName &&
      spaceName !== ""
    ) {
      const resultSpaces = allSpaces.filter((space) =>
        space.name.toLowerCase().includes(spaceName)
      );

      const spacesNameTag =
        resultSpaces.length > 0 ? (
          React.Children.toArray(
            resultSpaces.map((space) => (
              <div
                className="search-menu-item"
                onMouseDown={(evt) => {
                  evt.preventDefault(); // prevent to avoid on blur to get called and then re-render the UI
                }}
                onClick={() => {
                  goToUrl(
                    `/floor/${space.floorId}/area/${space.areaId}/spaceinfo/${space._id}`
                  );
                }}
              >
                {space.name}
              </div>
            ))
          )
        ) : (
          <div className="search-menu-item">Nenhum espaço foi encontrado.</div>
        );

      return (
        <div
          className="search-menu"
          style={{ width: searchBarRef.current.offsetWidth }}
        >
          {spacesNameTag}
        </div>
      );
    }
  };

  const handleChange = (evt) => {
    setSpaceName(evt.currentTarget.value);
  };

  return (
    <Navbar bg="light" variant="light" expand="lg">
      <Navbar.Brand href="/">GESTÃO DE ESPAÇOS</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto d-flex flex-wrap align-items-center">

          {isAuthenticated ? (
            <Nav.Link href="/home">
              <FaHome className="icon-left icon-purple" />
              Início
            </Nav.Link>
          ) : null}

          {!isAuthenticated ? null : (
            <Nav.Link href="/notifications">
              <MdNotificationsNone className="icon-left icon-purple" />
              Notificações
            </Nav.Link>
          )}

        {isAuthenticated ? (
            <NavDropdown title="Listar Espaços" id={"nav-dropdown-show-spaces"}>
                    <Select
                        isClearable={true}
                        placeholder="Selecione ou digite o espaço"
                        onChange={handleSpaceSelectChange}
                        options={spaceOptions}
                        className="filterSpace__selectInput"
                        styles={{
                            control: (base) => ({
                                ...base,
                                width: 275,
                                minWidth: 250,
                            }),
                        }}
                    />
            </NavDropdown>
        ) : null }

            {isAuthenticated ? (
            <Nav.Link href="/findFloor">
              <FaBuilding className="icon-left icon-purple" />
              Planta
            </Nav.Link>
          ) : null}

          {isAuthenticated &&
            (systemMasterManager ||
                systemManager || spaceManager) ? (
            <NavDropdown title="Gestão">
              <NavDropdown.Item href="/manage-system" id="manageSystem">
                Gerir Sistema
              </NavDropdown.Item>

              <NavDropdown.Item href="/myspaces">
                Espaços Geridos por Mim
              </NavDropdown.Item>
              {isAuthenticated && (systemMasterManager || systemManager) ? (
                <>
                    { systemMasterManager ? (
                            <NavDropdown.Item href="/delete-reservation"  id="deleteReservations">
                                Remover Reservas
                            </NavDropdown.Item>
                        ) : null
                    }

                  <NavDropdown.Item href="/permissions">
                    Gerir Permissões
                  </NavDropdown.Item>

                  <NavDropdown.Item href="/reservationReasons">
                    Gerir Motivos de Reservas
                  </NavDropdown.Item>

                    { systemMasterManager ? (
                        <>
                            <NavDropdown.Item href="/employees">
                                Gerir Utilizadores
                            </NavDropdown.Item>

                            <NavDropdown.Item href="/schools">
                                Gerir Escolas
                            </NavDropdown.Item>

                            <NavDropdown.Item href="/force-import-of-summaries">
                                Importar do Sumários
                            </NavDropdown.Item>
                        </>
                        ) : null
                    }
                </>
              ) : null}
            </NavDropdown>
          ) : null}

          {isAuthenticated && (spaceManager || systemManager || systemMasterManager) ? (
            <Nav.Link href="/spreadsheets">
              <FaRegFileExcel className="icon-left icon-purple" />
              Gerar Excel
            </Nav.Link>
          ) : null}

           {isAuthenticated && (systemManager || systemMasterManager) ? (
             <Nav.Link href="/info" className="system-info-link">
               <FaInfoCircle className="icon-left icon-purple" />
                 Informações do Sistema
             </Nav.Link>
           ) : null}
        </Nav>

          {isAuthenticated ? (
              <select className={`select-school ${isBlockOtherSchools ? 'read-only' : ''}`}
                      style={{width: "100px", marginRight: "30px"}}
                      onChange={handleSchoolSelectChange}
                      value={selectedSchoolCode}>
                      disabled={isBlockOtherSchools}

                  {allSchools.map((item, index) => (
                      <option key={index} value={item.code}>
                          {item.name}
                      </option>
                  ))}
              </select>
          ) : null }

          {isAuthenticated ? (
              <InputGroup
                  ref={searchBarRef}
                  style={{width: "200px", marginRight: "30px"}}
              >
                  <FormControl
                      placeholder="Pesquisar espaço"
                      aria-label="Nome do Espaço"
                      aria-describedby="basic-addon1"
                      onChange={handleChange}
              value={spaceName}
              onBlur={() => {
                setShouldOpenSearchMenu(false);
              }}
              onFocus={() => {
                setShouldOpenSearchMenu(true);
              }}
            />
            {dropdownSearch()}
          </InputGroup>
        ) : null}

        {isAuthenticated && (systemMasterManager || systemManager || spaceManager) ? (
          <Navbar.Text className="user-role">
            {(systemMasterManager || systemManager) ?
                (systemMasterManager ? "Administrador Geral do sistema" : "Administrador do sistema")
                : "Gestor de Espaços"}
          </Navbar.Text>
        ) : null}

        {/*<Form inline>*/}
        {/*  {isAuthenticated ? (*/}
        {/*    <NavLink className="navbar-username">*/}
        {/*      {employeeName}*/}
        {/*      <FiLogOut className="nav__logout" onClick={logout} />*/}
        {/*    </NavLink>*/}
        {/*  ) : (*/}
        {/*    <Nav.Link href="/">Login</Nav.Link>*/}
        {/*  )}*/}
        {/*</Form>*/}

          <Form inline>
              {isAuthenticated ? (
                  <Dropdown>
                      <Dropdown.Toggle
                          variant="link"
                          id="dropdown-basic"
                          className="navbar-username"
                      >
                          {employeeName}
                      </Dropdown.Toggle>

                      <Dropdown.Menu align="right">
                          <Dropdown.Item href="/profile">
                              <FiUser /> Perfil
                          </Dropdown.Item>
                          <Dropdown.Item onClick={logout}>
                              <FiLogOut /> Sair
                          </Dropdown.Item>
                      </Dropdown.Menu>
                  </Dropdown>
              ) : (
                  <Nav.Link href="/">Login</Nav.Link>
              )}
          </Form>

      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarCustom;
