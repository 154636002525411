import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Card, Button, Form, Alert, Row, Col } from "react-bootstrap";
import API_ROUTE from "../../utils/apiRoute";
import ActionFeedbackModal from "../../components/action-feedback-modal/action-feedback-modal.component";
import EmployeeEditModal from "../../components/manage-employees-modal/manage-employee-modal.component";
import useModal from "../../utils/useModal";

const ManageEmployees = () => {
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState("");
    const [variant, setVariant] = useState("success");
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);

    const {
        isShowing: isShowingActionFeedbackModal,
        toggle: toggleActionFeedbackModal,
    } = useModal();

    const {
        isShowing: isShowingEmployeeEditModal,
        toggle: toggleEmployeeEditModal,
    } = useModal();

    useEffect(() => {
        axios.get(`${API_ROUTE}employee/all`, {
            withCredentials: true,
        })
            .then(response => setEmployees(response.data.data.employees))
            .catch(error => console.error("Error fetching employees:", error));
    }, []);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (!file) {
            setMessage("Please select a file first.");
            setVariant("danger");
            return;
        }

        const formData = new FormData();
        formData.append("csvFile", file);

        try {
            await axios.post(`${API_ROUTE}employee/uploadEmployeesCsv`, formData, {
                withCredentials: true,
                headers: { "Content-Type": "multipart/form-data" },
            });
            toggleActionFeedbackModal();
        } catch (error) {
            const errorMessage = error.response?.data?.message || "Erro ao processar o CSV";
            setMessage(errorMessage);
            setVariant("danger");
        }
    };

    return (
        <Container className="d-flex justify-content-center align-items-center vh-100 mt-n5">
            <Row className="w-100">
                <Col md={6} className="d-flex justify-content-center">
                    <Card className="p-4 shadow-lg" style={{ width: "450px" }}>
                        <Card.Body>
                            <Card.Title className="text-center mb-3">Upload CSV</Card.Title>
                            <Form>
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Control type="file" accept=".csv" onChange={handleFileChange} />
                                </Form.Group>
                                <Button variant="primary" className="w-100" onClick={handleUpload}>
                                    Upload CSV
                                </Button>
                            </Form>
                            {message && <Alert variant={variant} className="mt-3 text-center">{message}</Alert>}
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6} className="d-flex justify-content-center">
                    <Card className="p-4 shadow-lg" style={{ width: "450px" }}>
                        <Card.Body>
                            <Card.Title className="text-center mb-3">Editar Funcionário</Card.Title>
                            <Button variant="primary" className="w-100" onClick={toggleEmployeeEditModal}>
                                Selecionar Funcionário
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <ActionFeedbackModal
                isShowing={isShowingActionFeedbackModal}
                toggle={toggleActionFeedbackModal}
                titleText="Criado os Utilizadores com Sucesso!"
                isActionCompleted={true}
            />

            <EmployeeEditModal
                isShowing={isShowingEmployeeEditModal}
                toggle={toggleEmployeeEditModal}
                employees={employees}
                selectedEmployee={selectedEmployee}
                setSelectedEmployee={setSelectedEmployee}
            />
        </Container>
    );
};

export default ManageEmployees;