import React, {useContext, useEffect, useState} from "react";
import ReactDOM from "react-dom";
import { Modal, Form, Button } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import useModal from '../../utils/useModal'
import ConfirmationModal from "../confirmation-modal/confirmation-modal.component";
import API_ROUTE from "../../utils/apiRoute";
import {GlobalContext} from "../../GlobalState";

const SelectEditArea = ({
  isShowing,
  toggle,
  setAreaId,
  areaId,
  toggleEditFloorModal,
  setTitleText,
  toggleActionFeedback,
  setIsActionCompleted,
  areaSelectType,
  toggleListArea,
  setFloorId,
  floorId,
}) => {
  const [allAreas, setAllAreas] = useState([]);
  const [allFloors, setAllFloors] = useState([]);

  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null);

  const { selectedSchoolCode, setSelectedSchoolCode } = useContext(GlobalContext);

  const { isShowing: isShowingConfirmationModal, toggle: toggleConfirmationModal } = useModal();

  const handleChangeArea = (selected) => {
    setAreaId(selected.value);
    setSelectedArea(selected);
  };

  const handleChangeFloor = (selected) => {
    setFloorId(selected.value);
    setSelectedFloor(selected);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (areaSelectType === "list") {
      toggleListArea();
      toggle();
    } else if (areaSelectType === "delete") {
      toggleConfirmationModal();
    } else {
      toggleEditFloorModal();
      toggle();
    }
  };

  const deleteArea = () => {
    (async () => {
      if (areaId !== null) {
        await axios
          .delete(`${API_ROUTE}area/${areaId}/`, {
            withCredentials: true,
          })
          .then((res) => {
            setTitleText("Área removida com sucesso!");
            setIsActionCompleted(true);
            toggleActionFeedback();
            toggleConfirmationModal();
            toggle();
          })
          .catch((err) => {
            setTitleText("Algo inesperado aconteceu, tente novamente!");
            setIsActionCompleted(false);
            toggleActionFeedback();
            toggleConfirmationModal();
            toggle();
            // alert(err);
          });
      }
    })();
  };

  useEffect(() => {
    if (floorId) {
      (async () => {
        await axios
          .get(`${API_ROUTE}area/byFloor/${floorId}`, {
            withCredentials: true,
          })
          .then((res) => {
            const tmpAllAreas = res.data.data.allAreas.map((area) => {
              return {
                value: area._id,
                label: area.name,
              };
            });
            if (tmpAllAreas.length > 0) {
              setAllAreas(tmpAllAreas);
              setAreaId(tmpAllAreas[0].value); // default initial value
              setSelectedArea(tmpAllAreas[0]);
            } else {
              setAllAreas([]);
              setAreaId(null); // default initial value
              setSelectedArea(null);
            }
          });
      })();
    }
  }, [floorId]);

  // get all Floors to create the select input
  useEffect(() => {
    (async () => {
      if (selectedSchoolCode) {
        await axios
            .get(`${API_ROUTE}floor/school/${selectedSchoolCode}`, {
              withCredentials: true,
            })
            .then((res) => {
              const tmpAllFloors = res.data.data.allFloorsBySchool.map((floor) => {
                return {
                  value: floor._id,
                  label: floor.name,
                };
              });
              setSelectedFloor(tmpAllFloors[0]);
              setFloorId(tmpAllFloors[0]?.value); // default initial value
              setAllFloors(tmpAllFloors);
            });
      }
    })();
  }, [setSelectedSchoolCode]);

  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      color: state.isSelected ? "#FFF" : styles.color,
      borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
      "&:hover": {
        color: "#FFF",
        backgroundColor: "#2684FF",
      },
    }),
    control: (base, state) => {
      let statusColor = colors.divider;

      if (state.selectProps.error) {
        // "state.selectProps" references the component props
        statusColor = colors.error;
      }

      return {
        ...base,
        boxShadow: state.isFocused
          ? "0 0 0 0.2rem rgba(120, 194, 173, 0.25)"
          : 0,
        borderColor: state.isFocused ? "#2684FF" : "#CED4DA",
        "&:hover": {
          borderColor: state.isFocused ? "#2684FF" : "#CED4DA",
        },
      };
    },
  };

  const colors = {
    divider: "#34eb49",
    error: "#ff3447",
  };

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Modal
            show={isShowing}
            onHide={() => {
              toggle();
            }}
          >
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {areaSelectType === "edit"
                    ? "Editar Área"
                    : areaSelectType === "delete"
                    ? "Remover Área"
                    : "Listar Áreas"}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form.Group controlId="validationCustomCoordinates">
                  <Form.Label>Selecione um Piso</Form.Label>
                  <Select
                    placeholder="Selecione ou digite o nome de um piso"
                    options={allFloors}
                    className="react-select"
                    value={selectedFloor}
                    onChange={handleChangeFloor}
                    styles={customStyles}
                    error={true}
                  />
                </Form.Group>

                {areaSelectType === "list" ? null : (
                  <Form.Group controlId="validationCustomCoordinates">
                    <Form.Label>
                      {areaSelectType === "edit"
                        ? "Selecione uma área para editar"
                        : areaSelectType === "delete"
                        ? "Selecione uma área para remover"
                        : null}
                    </Form.Label>
                    <Select
                      placeholder="Selecione ou digite o nome de uma área"
                      options={allAreas}
                      className="react-select"
                      value={selectedArea}
                      onChange={handleChangeArea}
                      styles={customStyles}
                      error={true}
                    />
                  </Form.Group>
                )}
              </Modal.Body>

              <Modal.Footer>
                <Button variant="danger" onClick={toggle}>
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="success"
                  // disabled={areaId ? false : true}
                  disabled={
                    areaSelectType === "list"
                      ? floorId
                        ? false
                        : true
                      : areaId
                      ? false
                      : true
                  }
                >
                  {areaSelectType === "edit"
                    ? "Editar Área"
                    : areaSelectType === "delete"
                    ? "Remover Área"
                    : "Listar Áreas"}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <ConfirmationModal
            isShowing={isShowingConfirmationModal}
            toggle={toggleConfirmationModal}
            handleConfirmation={deleteArea}
            titleText={"Remover Área"}
          />
        </React.Fragment>,
        document.body
      )
    : null;
};

export default SelectEditArea;
